import React from 'react';
import './LinkTab.scss';
import ICON from '../../../assets/link.svg';

const LinkTab = () => {
  return (
    <div className='tab-content'>
      <div className='i-box'>
        <img src={ICON} className='icon' alt="icon" />
        <input
          type='text'
          className='input-box'
          placeholder='Paste your link'
        />
      </div>
      <div className='toggle-box'>
        <div className="txt-s">
            Monetize
        </div>
        <label className='switch'>
          <input type='checkbox' />
          <span className='slider'></span>
        </label>
      </div>
      <button className="create-btn">
        Create Link 
      </button>
    </div>
  );
};

export default LinkTab;
