import React from 'react';
import './Navbar.scss';
import { Link, NavLink } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';

const Navbar = () => {
  const { user } = useAuth();
  return (
    <nav className='nav'>
      <Link className='logo' to='/'>
        Link<span className='col'>Tools</span>
      </Link>
      <ul className='navlinks'>
        <li>
          <NavLink to='/'>Home</NavLink>
        </li>
        <li>
          <NavLink to='/about'>About</NavLink>
        </li>
        <li>
          <NavLink to='/services'>Services</NavLink>
        </li>
        <li>
          <NavLink to='/contact'>Contact</NavLink>
        </li>
        {user != null && (
          <li>
            <NavLink to='/dashboard'>Dashboard</NavLink>
          </li>
        )}
      </ul>
      {user === null && (
        <Link to='/login'>
          <button className='btn'>Login</button>
        </Link>
      )}
    </nav>
  );
};

export default Navbar;
