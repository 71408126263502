import './App.css';
import { Routes, Route } from 'react-router-dom';

import { useLocation, Navigate } from 'react-router-dom';

import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Navbar from './components/Navbar/Navbar';
import Login from './pages/Login/Login';
import { AuthProvider, useAuth } from './context/AuthContext';
import Dashboard from './pages/Dashboard/Dashboard';

function App() {
  // Protected routes
  function RequireRoute({ children }) {
    const { user } = useAuth();
    const location = useLocation();
    console.log(user);
    return user != null ? (
      children
    ) : (
      <Navigate to='/login' replace state={{path:location.path }} />
    );
  }


  return (
    <AuthProvider>
      <div className='app'>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/service' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />
          <Route path='/dashboard'
          element={<RequireRoute><Dashboard /></RequireRoute>} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
