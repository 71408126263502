import React from 'react'
import './Home.scss'
import Tabs from '../../components/Tabs/Tabs'

const Home = () => {
  return (
    <section className="home">
      <div className="sec-1">
        <h1 className="main-txt">
        The only <span className='grad'>all in one</span> Link tools<br></br>for your Business
        </h1>
      </div>
      <div className="sec-2">
        <Tabs/>
      </div>
    </section>
  )
}

export default Home