import React from 'react'
import './Tabs.scss'
import LinkTab from './LinkTab/LinkTab'
import FileTab from './FileTab/FileTab'
import VideoTab from './VideoTab/VideoTab'
import TextTab from './TextTab/TextTab'

import { useState } from 'react'

const Tabs = () => {

  const [isSelected,setIsSelected] = useState(1);
  return (
    <div className="box">
        <div className="top-box">
            <div 
            onClick={()=>setIsSelected(1)}
            className={isSelected===1?'tab highlight':'tab'}
            >Link</div>
            <div
            onClick={()=>setIsSelected(2)}
            className={isSelected===2?'tab highlight':'tab'}
            >File</div>
            <div 
            onClick={()=>setIsSelected(3)}
            className={isSelected===3?'tab highlight':'tab'}
            >Video</div>
            <div 
            onClick={()=>setIsSelected(4)}
            className={isSelected===4?'tab highlight':'tab'}
            >Text</div>
        </div>
        <div className="bottom-box">
          {isSelected===1 && <LinkTab/>}
          {isSelected===2 && <FileTab/>}
          {isSelected===3 && <VideoTab/>}
          {isSelected===4 && <TextTab/>}
        </div>
    </div>
  )
}

export default Tabs